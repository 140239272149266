<template>
  <div class="flex items-center space-x-8">
    <RevButtonRounded
      :alternative-text="i18n(translations.backButton)"
      :icon="IconArrowLeft"
      :to="{ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF }"
      variant="secondary"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import translations from './Header.translations'

const i18n = useI18n()
</script>
